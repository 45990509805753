@import '../../assets/styles/mixins.scss';

.modify-table {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 5px 6px 5px 8px;
    overflow-wrap: break-word;
  }

  .ant-table {
    font-size: 13px;
    font-weight: 500;
  }

  .ant-table-thead > tr > th {
    padding-left: 10px;
    font-weight: 600;
    height: 52px;
    background-color: $palete-light-blue;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.filter_sub_div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
}

.ant-form-item label {
  font-size: 14px;
}

// .all-clinic-data {
//   .utils__content {
//     padding: 10px 0 30px;
//   }
// }

.avatarlogo {
  width: 128px !important;
  height: 128px !important;
  border-radius: 50%;
}

.ant-form-item-required::before {
  content: '*';
}

.editable-cell {
  position: relative;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 0;
}

.editable-cell-value-wrap {
  padding: 0;
  cursor: pointer;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

.edit-checkBox {
  .ant-checkbox-inner {
    border: 1.2px solid black;
  }
}

.drawer-header-button {
  z-index: 10;
  display: block;
  width: 50px;
  height: 56px;
  padding: 0;
  color: rgba(61, 50, 50, 0.45);
  font-weight: 700;
  font-size: 24px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  text-rendering: auto;
}

.change-invo-drawer {
  .ant-drawer-body {
    scrollbar-width: thin;
    padding: 0;
  }

  .ant-drawer-wrapper-body {
    overflow: unset;
  }

  .ant-drawer-close {
    font-size: 24px;
  }
}

.clinic_sub_container {
  .clinic_sub_top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    background-color: #fff;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.12);
  }
}

.clinic_list {
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  align-content: center;
  padding: 7px 0;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 8px;

  &:hover {
    border-color: #40a9ff;
    border-style: dashed;
  }

  p {
    margin-bottom: 0;
  }
}

.modify-select {
  .ant-select-arrow {
    display: none !important;
    width: 0 !important;
    overflow: hidden !important;
  }

  .ant-select-selection--single .ant-select-selection__rendered {
    margin-right: 0 !important;
  }
}
