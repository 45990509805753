// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN FORM */

.ant-form input[type='file'] {
  min-height: 40px;
}

.ant-form input.ant-input-sm[type='file'] {
  min-height: 48px;
}

.ant-form input.ant-input-lg[type='file'] {
  min-height: 52px;
}

.ant-form-item {
  margin-bottom: 12px;
}

.ant-form-vertical .ant-form-item-label {
  margin: 0;
  padding: 0 0 6px;
}

.ant-form-vertical .ant-form-item {
  padding-bottom: 6px;
}

.ant-form label {
  margin-bottom: 0;
}

.ant-input {
  border-radius: 0;
}
.ant-select-selection {
  border-radius: 0;
}

.ant-form-item-label {
  white-space: normal;
  font-size: 14px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.65);
  line-height: 32px;
}

.ant-form-item-required::before {
  content: '*';
}

.ant-form-item > .ant-form-item,
.ant-form-item :not(.ant-form) > .ant-form-item {
  margin-top: -2px;
}

form .ant-select:only-child,
form .ant-cascader-picker:only-child {
  display: inline-block;
}

.ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab {
  margin-bottom: 0;
  border-radius: 0;
  background-color: white;
}

.ant-tree li {
  // white-space: normal;
  word-wrap: break-word;
  list-style: none;
  min-height: fit-content;
  height: fit-content;
}
