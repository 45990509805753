.cardDiv {
  background: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  padding: 20px;
}
.parentDiv {
  display: flex;
  margin: 5px 30px 5px 0;
}
.title {
  font-size: 15px;
  font-weight: bolder;
  margin: auto;
  margin-left: 0;
  margin-right: 5px;
}
p {
  font-size: 15px;
  font-weight: bolder;
  margin-top: 10px;
}
.label {
  font-size: 12px;
  font-weight: bold;
  margin-left: 10;
  color: 'black';
}
.inputField {
  border: 1;
  font-size: 15px;
  color: #000;
  background-color: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 10px;
  margin: 10px 10px 10px 10px;
  border-radius: 5px;
}
.inputBlock {
  display: flex;
  flex-direction: column;
  width: 30%;
  padding: 10;
}
.content {
  padding: 13px 20px;
  width: 100%;
  background-color: '#FFFFFF';
  border-radius: 10px;
  margin: auto;
  margin-top: 10;
  font-size: 20;
}
.centeredDiv {
  justify-content: center;
  display: flex;
}
