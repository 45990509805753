.sticky {
  z-index: 100;
  margin-top: 55px;
}

table,
th,
td {
  height: 50px;
}

th,
td {
  padding: 5px;
  text-align: left;
}

.ytp-pause-overlay {
  display: none;
}
