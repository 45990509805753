@import 'assets/styles/mixins.scss';

.celerationReport {
  .charts {
    padding: 10px 0 10px 10px;

    h3 {
      font-weight: bold;
      padding-top: 1px;
    }
  }

  .ant-table-thead > tr > th {
    padding: 12px 8px !important;
    font-size: 13px !important;
    background-color: $palete-light !important;
    font-weight: bold;
  }

  .ant-table-body {
    margin: 0 !important;
  }

  .ant-table-tbody > tr > td {
    padding: 12px 8px !important;
    font-size: 13px !important;
  }
}

.addOrEditFormCelerationChart {
  .ant-form-item {
    margin-bottom: 8px !important;
  }

  .ant-form-item label {
    font-size: 14px !important;
    font-weight: bold !important;
  }
}
