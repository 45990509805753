.clinicProfile {
  background-color: #fff;
  padding: 0;

  .profilePicture {
    width: 60px;
    height: 60px;
    margin-right: 15px;
    border-radius: 75%;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  }

  .peakAutomaticCheckbox {
    font-weight: bold;
    margin-right: 5px;
    margin-top: 15px;
  }

  .profileSettingMenu {
    font-weight: bold;
    font-size: 15px;
    margin-right: 0;
    padding-right: 0;
    margin-left: 10px;
  }
}

.clinicProfileTabs {
  margin-top: 20px;
  border: 1px solid #dfdfdf;

  .ant-tabs-tab {
    height: 50px !important;
    line-height: 48px !important;
    text-align: left !important;
    font-weight: bold;
    width: 240px;
    font-size: 120%;
  }

  .ant-tabs-tab-active {
    background: '#f7f7f7' !important;
  }

  .ant-tabs-bar .ant-tabs-left-bar .ant-tabs-card-bar {
    min-height: 400px !important;
  }

  .ant-tabs .ant-tabs-left-content {
    padding-left: 0 !important;
  }

  .ant-tabs-content {
    padding: 20px !important;
  }

  .ant-table-header-column {
    font-weight: bold;
  }

  .profileForm {
    .ant-form-item label {
      font-size: 14px;
      font-weight: bold;
    }
  }

  .tabTitle {
    text-transform: uppercase;
    font-size: 1.38rem;
    color: rgb(14, 11, 32);
    margin: 12px 0 17px;
    font-weight: bold;
  }

  .addButton {
    font-size: 24px;
    margin-top: 5px;
    font-weight: 600;
  }
}

.invoice-related {
  .ant-form-item-required::before {
    content: '*';
  }
}

.tabs-panel-archive {
  margin-bottom: 5px;
  overflow: hidden;
  background-color: white;
  border: 0;
  padding: 1.5rem;
  font-size: medium;
  font-weight: 500;
}

.tabs-archive {
  margin-top: 10px;
  background: rgba(255, 255, 255, 0.04);
  border: 0;
}
