.utils__content {
  padding: 0;
}

.collapseMenu {
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  .ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid #bcbcbc;
    border-radius: 0;

    .ant-collapse-header {
      padding: 17px 20px;
      font-size: 16px;
      font-weight: 500;

      .ant-collapse-arrow {
        right: 16px;
        left: unset;
      }
    }
  }
}
