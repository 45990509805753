@import '../../assets/styles/mixins.scss';

.filter-parent {
  display: flex;
  background-color: 'red';
  width: 100%;
  justify-content: space-between;
}
.filter-parent > span {
  margin: 10px 5px;
}

.update-bank-details {
  .ant-form-item-required::before {
    content: '*';
  }
}

.ant-modals-design {
  .ant-modal-body {
    padding: 16px 24px;
  }
}

.table-outer-border {
  .ant-table {
    border: 1px solid #e8e8e8;
  }
}

.table-input-field {
  .ant-form-item {
    margin-bottom: 0;
  }
}

.ant-table.ant-table-bordered .ant-table-footer {
  background-color: $palete-light-blue;
}
.ant-table-tbody > tr > td {
  height: 5px;
  padding: 4px;
  padding-left: 6px;
  padding-right: 6px;
}

@media screen and (max-width: 1000px) {
  .filter-parent {
    flex-wrap: wrap;
  }
}
