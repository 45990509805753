.behaviorData {
  margin: 10px;

  .ant-tabs-bar {
    margin: 0;
  }

  .ant-tabs-tabpane {
    // border: 1px solid #dfdfdf;
    padding: 10px 0;
  }

  .ant-form-item {
    margin-bottom: 5px;
  }

  .ant-form-item label {
    font-size: 14px;
    font-weight: bold;
  }

  .ant-table-thead > tr > th {
    padding: 12px 8px !important;
    font-size: 13px !important;
    background-color: rgba(0, 0, 0, 0.04) !important;
    font-weight: bold !important;
  }

  .ant-table-body {
    margin: 0 !important;
  }

  .ant-table-tbody > tr > td {
    padding: 12px 8px !important;
    font-size: 13px !important;
  }
}

.reports-filter-card {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  gap: 1rem;
  padding: 0.4rem 1rem;
  background-color: white;
  border-radius: 0.2rem;
  height: fit-content;
  overflow: hidden;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0 1px 1px 0;
}

.reports-graph-card {
  background: white;
  border-radius: 0.2rem;
  padding: 0.5rem;
  height: 450px;
  transition: all 300ms linear;
}

.reports-table-card {
  background-color: white;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0 1px 2px 0;
}

.graphsContainer {
  display: flex;
  overflow: scroll;
  overflow-y: hidden;
  gap: 1rem;
  scroll-behavior: smooth;
  padding-bottom: 0.5rem;
  scroll-snap-type: x proximity;

  .reports-graph-card {
    scroll-snap-align: center;
  }

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #d4d4d8;
    border-radius: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    // color: #1867d4;
    border-radius: 0.5rem;
    background-color: #64748bb2;
  }
}

.slider-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem;
  color: #424953;
  z-index: 100;
  height: 3rem;
  width: 3rem;
  background-color: rgba(0, 0, 0, 0.144);
  display: flex;
  align-items: center;
  justify-content: center;
}

.next-slide-btn {
  right: 1rem;
}

.prev-slide-btn {
  left: 1rem;
}
