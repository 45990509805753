// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN BACKTOP */

.ant-table-thead > tr > th {
  background-color: #dbe2ef !important;
}

.ant-table-body {
  background-color: white;
}

.ant-table-bordered .ant-table-thead > tr > th {
  background-color: #dbe2ef !important;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  background-color: #dbe2ef !important;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  background-color: #fff !important;
}

.ant-drawer-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
}

.ant-drawer-content-wrapper {
  transition: all 0.3s ease 0s;
}

.ant-upload.ant-upload-drag {
  background: #f2f4f8;
  background-color: #e5ebff;
  opacity: 0.69;
  border: 1px dashed #0b35b3;
  box-sizing: border-box;
  border-radius: 11px;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
  margin-bottom: 12px;
}

.ant-btn {
  border-radius: 2px;
}

.ant-btn > .anticon {
  line-height: 0.7;
}
