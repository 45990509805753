@import '../../assets/styles/mixins.scss';
/* table */

.table-global {
  .ant-table {
    font-size: 13px;
  }

  .ant-table-thead > tr > th {
    font-weight: 600;
    background-color: $palete-light;
  }

  .ant-table-tbody > tr > td {
    background-color: white;
    color: rgba(0, 0, 0, 0.87);
  }
}

.peak-block-report {
  @extend .table-global;
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 12px 8px;
    overflow-wrap: break-word;
  }
}

.goal-table {
  @extend .table-global;
  .ant-table {
    font-size: 14px;
  }
}

.session-table {
  @extend .table-global;
  .ant-table-thead > tr > th {
    padding: 6px 5px;
    text-align: center;
  }
  /* row data */
  .ant-table-tbody > tr > td {
    padding: 4px 5px;
    text-align: center;
  }
}

.response-rate-table {
  @extend .table-global;
  .ant-table
    > .ant-table-content
    > .ant-table-fixed-left
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-tbody
    > tr
    > td {
    max-height: 40px;
    padding: 0 8px;
  }

  /* row data */
  .ant-table-tbody > tr > td {
    font-size: 13px;
  }

  .ant-table-thead > tr > th {
    padding: 8px;
  }

  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td {
    background: unset; //Change the existing color to `unset`
  }
}

.peak-block-detail-table {
  @extend .table-global;
  .ant-table,
  .ant-table-middle,
  .ant-table-bordered,
  .ant-table-scroll-position-left {
    font-size: 13px !important;
  }
}

.target-response-report {
  @extend .table-global;
  .ant-table {
    font-size: 14px;
  }
}
