.ant-form label {
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 11px;
}

.ant-form-item-required::before {
  content: '';
}

.ant-form-item {
  margin-bottom: 9px;
}

// textarea.ant-input {
//     height: 120px !important;
// }
