@import '../../../assets/styles/mixins.scss';

.behaviorReport {
  .filterCard {
    background-color: $palete-light;
    padding: 5px 10px;
    margin: 0;
    height: 50px;
    overflow: hidden;

    .label {
      font-size: 18px;
      color: #000;
      padding-top: 2px;
      display: block;
    }

    .datePicker {
      margin-left: auto;
      width: 235px;
    }
  }

  .ant-table-thead > tr > th {
    padding: 12px 8px !important;
    font-size: 13px !important;
    background-color: $palete-light !important;
    font-weight: bold;
  }

  .ant-table-body {
    margin: 0 !important;
  }

  .ant-table-tbody > tr > td {
    padding: 12px 8px !important;
    font-size: 13px !important;
  }

  .chart {
    background: $palete-light;
    border-radius: 10;
    padding: 10px;
    margin: 10px 0 0 10px;
    overflow: hidden;

    .panel {
      border-radius: 20;
      display: block;
      width: 100%;
      min-height: 300px;
    }
  }

  .frequencyTable {
    margin: 10px;
  }

  .valueText {
    font-weight: bold;
    display: inline-block;
    width: 24px;
    font-size: 1.2rem;
  }

  .labelText {
    font-size: 0.9rem;
    color: #888;
    font-style: italic;
  }
}

.space-align-container {
  margin: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.space-align-block {
  border: 2px solid #aaa6a6;
  box-shadow: 5px;
  border-radius: 5px;
  flex: none;
  padding: 30px;
  width: 40%;
  justify-content: center;
  align-items: center;
}

.behavior__aggregate__table {
  * {
    border-color: rgb(192, 192, 192) !important;
  }
}
