.uploader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.webcam-container {
  position: relative;
  display: inline-block;
}

.webcam {
  display: block;
  border: 3px solid #ccc;
  border-radius: 100%;
  width: 300px;
}

.progress-container {
  position: absolute;
  top: -10px;
  left: -10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-bar {
  fill: none;
  stroke: #1890ff;
  stroke-width: 10;
  stroke-dasharray: 440;
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 0.3s ease-in-out;
}
