.sessionTable {
  margin-top: 1rem;

  & th,
  tr,
  td {
    border-color: rgb(187, 187, 187) !important;
  }

  & th {
    color: #1f2937;
    font-weight: bold !important;
  }

  & td,
  th {
    padding-inline: 0.5rem !important;
  }

  & td {
    color: #374151;
    font-size: 0.95rem;
  }
}

.sessionReport__columnFilter_header {
  padding: 1rem 2rem;
  background-color: white;
  margin-top: 1rem;
  display: flex;
  border-radius: 0.5rem;
  justify-content: space-between;
  align-items: center;
}

.sessionReport_columnOptions {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .columnOptions {
    display: flex;
    gap: 1rem;
    flex-direction: column;
  }
}

$tableBorderWidth: 3px;

$dateWiseSessionColor: #ef4444;

$sessionColor: #2563eb;

$tagetColor: black;

$stimulusColor: #f080b8;

$stepsColor: #0d9488;

$promptColor: #ff9c52;

.sessionReport__colorCodes {
  background-color: white;
  padding: 0.5rem 1rem;
  display: flex;
  gap: 1rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  width: fit-content;
  // margin-left: auto;

  > div::before {
    content: '';
    min-width: 1rem;
    min-height: 1rem;
    display: inline-block;
    margin-right: 0.5rem;
    vertical-align: middle;
    border-radius: 2rem;
    margin-bottom: 2px;
  }

  .dateWise::before {
    background-color: $dateWiseSessionColor;
  }

  .session::before {
    background-color: $sessionColor;
  }

  .target::before {
    background-color: $tagetColor;
  }

  .stimulus::before {
    background-color: $stimulusColor;
  }

  .steps::before {
    background-color: $stepsColor;
  }

  .prompt::before {
    background-color: $promptColor;
  }
}

// .sessionTableRow__dateWise {
//   >td:first-child {
//     color: $dateWiseSessionColor;
//     border-right: $tableBorderWidth solid currentColor !important;
//   }
// }

// .sessionTableRow__session {
//   >td:first-child {
//     color: $sessionColor;
//     border-right: $tableBorderWidth solid currentColor !important;
//   }
// }

.sessionTableRow__target {
  > td:first-child {
    color: $tagetColor;
    border-right: $tableBorderWidth solid currentColor !important;
  }
}

.sessionTableRow__steps {
  > td:first-child {
    color: $stepsColor;
    border-right: $tableBorderWidth solid currentColor !important;
  }
}

.sessionTableRow__stimulus {
  > td:first-child {
    color: $stimulusColor;
    border-right: $tableBorderWidth solid currentColor !important;
  }
}

.sessionTableRow__prompt {
  > td:first-child {
    color: $promptColor;
    border-right: $tableBorderWidth solid currentColor !important;
  }
}
