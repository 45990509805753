@import '~bootstrap-css-only/css/bootstrap.min.css';
@import 'assets/styles/Antd/antd.cleanui';
@import 'assets/styles/CleanUI/cleanui';
@import 'assets/styles/Bootstrap/bootstrap.cleanui';
@import 'assets/styles/Chartist/chartist.cleanui';
@import 'assets/styles/Nprogress/nprogress.cleanui';
@import 'assets/styles/Rcdrawer/rcdrawer.cleanui';
@import '~c3/c3.min.css';
@import '~@fullcalendar/common/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import 'assets/styles/mixins.scss';

.claims-sections {
  margin-top: 1rem;
  background-color: white;
  width: 100%;
  max-width: 120rem;
  margin-inline: auto;
  padding: 1rem;
  border-radius: 0.5rem;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}

.custom-scrollbar {
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background-color: #e5e5e5;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #bec1c6;
  }
}

.primary-blue-btn {
  background-color: $submit-blue;
  color: white;
  border-color: $submit-blue;

  &:hover {
    opacity: 0.85;
  }
}

.primary-orange-btn {
  background-color: $orange-primary-btn;
  border-color: $orange-primary-btn;
  color: white;

  &:hover {
    background-color: $orange-primary-btn;
    border-color: $orange-primary-btn;
    color: white;
    opacity: 0.8;
  }
}

.ant-pagination-simple-pager {
  input {
    border: none !important;
    pointer-events: none !important;
    padding: 3px !important;
  }
}

.ant-table-tbody > tr > td {
  height: 5px;
  padding: 4px;
  padding-left: 6px;
  padding-right: 6px;
}

tr.ant-table-expanded-row td > .ant-table-wrapper {
  padding: 15px !important;
}
